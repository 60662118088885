
import { defineComponent } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import VehicleUsage from "@/components/reusable/VehicleUsage.vue";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import VehicleColors from "@/views/reusable/buy-insurance/motor-insurance/vehicle-colours.json";
import VehicleBodyTypes from "@/views/reusable/buy-insurance/motor-insurance/vehicle-body-types.json";
import "viewerjs/dist/viewer.css";
import $ from "jquery";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "motor-renewal-form-component",
  components: {
    Field,
    ErrorMessage,
    ImagesUploader,
    VehicleUsage,
  },
  props: {
    covers: Array,
    insurancePackage: String,
    grandPremium: Number,
    grandSumInsured: Number,
    metadatas: Array,
    leadInsurer: String,
    cartItemPublicId: String,
    premiumCalculationRoute: String,
    viewUploadedImages: Boolean,
  },
  data() {
    return {
      colorChart: ["/media/packages/vehicle-color-chart.jpeg"],
      images: [
        "/media/packages/car-body-types-1.jpeg",
        "/media/packages/car-body-types-2.jpeg",
      ],
      rates: {
        comprehensiveMotorRate: "",
      },
      underwriters: [],
      metadata: [
        {
          vehicleOwner: "",
          package: "",
          vehicleValue: "",
          vehiclePremium: 0.0 as number,
          chassisNumber: "",
          engineNumber: "",
          engineCapacity: "",
          vehicleColor: "",
          bodyType: "",
          vehicleLicense: "",
          vehicleImages: "",
          status: "",
        },
      ],
      totalPremium: 0.0 as number,
      totalSumInsured: 0.0 as number,
      insurancePackageType: "" as any,
      businessClass: "MOT",
      vehicleColors: VehicleColors,
      vehicleBodyTypes: VehicleBodyTypes,
      imageURL: "",
    };
  },
  created() {
    // setTimeout(() => {
    this.metadata = this.metadatas as any;
    this.insurancePackageType = this.insurancePackage;
    this.totalPremium = this.grandPremium as number;
    this.totalSumInsured = this.grandSumInsured as number;
    // console.table(this.totalSumInsured);
    // console.table(this.totalPremium);
    // }, 3000);

    // Policy images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;
    this.getMotorUnderwriters();
  },
  methods: {
    premium(event, position) {
      // Add comma and allow decimal point vehicle value field
      variables.numberFormatter(event.target.id);
      const newValue = event.target.value;

      // Remove comma from vehicle value
      const value = variables.removeComma(event.target.value);

      // Calculate for third-party
      if (isNaN(value) == false && [5000, 15000].includes(value)) {
        variables.premiumCalculator(1, value, event.target.ariaValueText);

        // Call vehicle premium calculator
        this.vehiclePremiumCalculator(1, value, position);
      }

      if (isNaN(value) == false) {
        const payload = {
          businessClass: "MOT",
          value: value,
          rate: event.target.accessKey,
          package: event.target.accessKey,
        } as object;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(`${this.premiumCalculationRoute}`, payload)
            .then(({ data }) => {
              // Calculate premium from vehicle value
              variables.premiumCalculator(
                1,
                data.data,
                event.target.ariaValueText
              );

              // Call vehicle premium calculator
              this.vehiclePremiumCalculator(1, data.data, position);

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
              this.$nextTick(() => (event.target.value = newValue));
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }

      // Append total sum insured value tot total sum insured field
      this.totalSumInsured = variables.totalSumInsured(
        value,
        event.target.title
      );
    },
    addDynamicField() {
      this.metadata.push({
        vehicleOwner: "",
        package: "",
        vehicleValue: "",
        vehiclePremium: 0.0 as number,
        chassisNumber: "",
        engineNumber: "",
        engineCapacity: "",
        vehicleColor: "",
        bodyType: "",
        vehicleLicense: "",
        vehicleImages: "",
        status: "",
      });

      variables.toastAlert("Row added", "success");
    },
    removeDynamicField(index) {
      // Remove dynamic field
      // this.metadata.splice(index, 1);
      $(".remove-" + index).remove();
      variables.toastAlert("Row removed", "success");

      setTimeout(() => {
        // Update total premium
        this.totalPremium = variables.totalPremiumValue();
      }, 200);
    },
    getMotorUnderwriters() {
      //Fetch list of States in Nigeria
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.MOTOR_UNDERWRITERS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.underwriters = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    vehicleLicenseSelected(event, position) {
      this.metadata[position].vehicleLicense = event.target.files[0];
    },
    vehicleImagesSelected(event, position) {
      this.metadata[position].vehicleImages = event.target.files;
    },
    showBodyTypes() {
      this.$viewerApi({
        images: this.images,
      });
    },
    showColorChart() {
      this.$viewerApi({
        images: this.colorChart,
      });
    },
    vehiclePremiumCalculator(rate: number, value: number, position: number) {
      this.metadata[position].vehiclePremium = +variables.vehiclePremium(
        rate,
        value
      );
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    },
    deleteImage(index, fileName) {
      const route = `${variables.CART_ROUTE}/${this.cartItemPublicId}/${fileName}`;
      Swal.fire({
        title: "Are you sure you want to delete this image?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007ae1",
        cancelButtonColor: "#ff3e61",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value == true) {
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.delete(route)
              .then(({ data }) => {
                //Assign data to form fields
                variables.toastAlert(data.data, "success");
                $(".remove-image-" + index).remove();
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }
      });
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    // motorCoverPublicId(publicId: string): string {
    //   return (this.selectedCoverId = publicId);
    // },
  },
});
